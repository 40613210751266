import { useLocation, Link } from 'react-router-dom';
import { useMenu } from '../hook/UseMenu';
import logoImage from "../resources/logo.png";

import './ResponsiveNavBar.css';

export function ResponsiveNavBar() {
  const { menus } = useMenu();
  const { pathname } = useLocation();
  // const navigate = useNavigate();

  return (
    <div className="ResponsiveNavBar">
      <img src={logoImage} width="100px" style={{ aspectRatio: '552 / 100' }} />
      <div className="menu">
        {
          menus.map((item, index) => {
            const className = (pathname === item.path) ? 'title_active' : '';
  
            return (
              <div key={index}>
                <div className={className}>
                  <Link to={item.path} className='link'>{ item.title }</Link>
                </div>
              </div>
            );
          })
        }
      </div>
      <div style={{ width: '100px' }} />
    </div>
  );
}
