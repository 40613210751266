import { useLocation, Link } from 'react-router-dom';
import { useMenu } from '../hook/UseMenu';

import './MobileNavBar.css';

export function MobileNavBar() {
  const { menus } = useMenu();
  const { pathname } = useLocation();

  return (
    <div className="MobileNavBar">
      <div className="menus">
        {
          menus.map((item, index) => {
            const className = (pathname === item.path) ? 'item_active' : '';
  
            return (
              <div key={index} className='item'>
                <div className={className}>
                  <Link to={item.path} className='link'>{ item.title }</Link>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
