import { useLocation } from "react-router-dom";
import BasicMeataBearImg from "../resources/preview-basic.gif";
import ProMeataBearImg from "../resources/preview-pro.gif";
import EliteMeataBearImg from "../resources/preview-elite.gif";
import Pathname from "../util/Pathname";

export default function ResponsiveMetaBearPicture(): JSX.Element {
  const { pathname } = useLocation();

  const changeImg = () => {
    if (pathname === Pathname.basic) {
      return (
        <img
          src={BasicMeataBearImg}
          style={{
            transform: "translate(1%, 13%)",
            width: "100%",
            maxWidth: "220px",
          }}
        />
      );
    } else if (pathname === Pathname.pro) {
      return (
        <img
          src={ProMeataBearImg}
          style={{
            transform: "translate(1%, 13%)",
            width: "100%",
            maxWidth: "220px",
          }}
        />
      );
    } else if (pathname === Pathname.elite) {
      return (
        <img
          src={EliteMeataBearImg}
          style={{
            transform: "translate(1%, 13%)",
            width: "100%",
            maxWidth: "220px",
          }}
        />
      );
    } else {
      return (
        <img
          src={BasicMeataBearImg}
          style={{
            transform: "translate(1%, 13%)",
            width: "100%",
            maxWidth: "220px",
          }}
        />
      );
    }
  };

  return changeImg();
}
