import Countdown from "react-countdown";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: theme.spacing(0),
      "& > *": {
        margin: theme.spacing(0.4),
        width: theme.spacing(6),
        height: theme.spacing(6),
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        background: "#384457",
        color: "white",
        borderRadius: 5,
        fontSize: 10,
      },
    },
    done: {
      display: "flex",
      margin: 0,
      marginBottom: theme.spacing(0.5),
      height: theme.spacing(3.5),
      padding: theme.spacing(1),
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      background: "#384457",
      color: "white",
      borderRadius: 5,
      fontWeight: "bold",
      fontSize: 18,
    },
    item: {
      fontWeight: "bold",
      fontSize: 18,
    },
  })
);

interface MintCountdownProps {
  date: Date | undefined;
  style?: React.CSSProperties;
  status?: string;
  onComplete?: () => void;
}

export const MintCountdown: React.FC<MintCountdownProps> = ({
  date,
  status,
  onComplete,
}) => {
  const classes = useStyles();
  const renderCountdown = () => {
    return status ? <span className={classes.done}>{status}</span> : null;
  };

  if (date) {
    return (
      <Countdown
        date={date}
        onComplete={onComplete}
        renderer={renderCountdown}
      />
    );
  } else {
    return null;
  }
};
