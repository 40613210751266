import { useLocation } from "react-router-dom";
import BasicTitleImg from "../resources/title-basic.png";
import ProTitleImg from "../resources/title-pro.png";
import EliteTitleImg from "../resources/title-elite.png";
import titleImage from "../resources/title.png";
import Pathname from "../util/Pathname";

export default function ResponsiveTitlePicture(): JSX.Element {
  const { pathname } = useLocation();

  const changeImg = () => {
    if (pathname === Pathname.basic) {
      return (
        <img
          src={BasicTitleImg}
          style={{
            width: "100%",
            maxWidth: "350px",
          }}
        />
      );
    } else if (pathname === Pathname.pro) {
      return (
        <img
          src={ProTitleImg}
          style={{
            width: "100%",
            maxWidth: "350px",
          }}
        />
      );
    } else if (pathname === Pathname.elite) {
      return (
        <img
          src={EliteTitleImg}
          style={{
            width: "100%",
            maxWidth: "350px",
          }}
        />
      );
    } else {
      return (
        <img
          src={titleImage}
          style={{
            width: "100%",
            maxWidth: "",
          }}
        />
      );
    }
  };

  return changeImg();
}
