import { useMemo } from 'react';
import Pathname from '../util/Pathname';

export type MenuConfig = {
  title: string;
  path: string;
};

export function useMenu() {
  const menus: MenuConfig[] = useMemo(() => {
    return [{
      title: 'Basic',
      path: Pathname.basic,
    }, {
      title: 'Elite',
      path: Pathname.elite,
    }, {
      title: 'Pro',
      path: Pathname.pro,
    }];
  }, []);

  return {
    menus,
  };
}
