import "./App.css";
import { Suspense, useMemo } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Pathname from "./util/Pathname";
import * as anchor from "@project-serum/anchor";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const getCandyMachineId = (
  pathName: string
): anchor.web3.PublicKey | undefined => {
  try {
    if (pathName === Pathname.basic) {
      return new anchor.web3.PublicKey(
        process.env.REACT_APP_CANDY_MACHINE_ID_BASIC!
      );
    } else if (pathName === Pathname.elite) {
      return new anchor.web3.PublicKey(
        process.env.REACT_APP_CANDY_MACHINE_ID_ELITE!
      );
    } else if (pathName === Pathname.pro) {
      return new anchor.web3.PublicKey(
        process.env.REACT_APP_CANDY_MACHINE_ID_PRO!
      );
    } else {
      return undefined;
    }
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Suspense fallback={<div />}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path={Pathname.basic}
                    element={
                      <Home
                        candyMachineId={getCandyMachineId(Pathname.basic)}
                        connection={connection}
                        txTimeout={DEFAULT_TIMEOUT}
                        rpcHost={rpcHost}
                        network={network}
                        error={error}
                      />
                    }
                  />
                  <Route
                    path={Pathname.pro}
                    element={
                      <Home
                        candyMachineId={getCandyMachineId(Pathname.pro)}
                        connection={connection}
                        txTimeout={DEFAULT_TIMEOUT}
                        rpcHost={rpcHost}
                        network={network}
                        error={error}
                      />
                    }
                  />
                  <Route
                    path={Pathname.elite}
                    element={
                      <Home
                        candyMachineId={getCandyMachineId(Pathname.elite)}
                        connection={connection}
                        txTimeout={DEFAULT_TIMEOUT}
                        rpcHost={rpcHost}
                        network={network}
                        error={error}
                      />
                    }
                  />
                  <Route path="*" element={<Navigate to={Pathname.basic} />} />
                </Routes>
              </BrowserRouter>
            </Suspense>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
